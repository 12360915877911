
.aboutme {
    max-width: 90%;
    margin: 0 auto;
    padding: 10px 25px;
    background: #F2E6E6;
}

.aboutme p {
    font-size: 18px;
    font-family: 'PT Sans', sans-serif;
    line-height: 29px;
    text-indent: 40px;
}

@media (min-width: 500px) {
    .aboutme {
        max-width: 55%;
        padding: 15px 40px;
    }
    .aboutme p {
        font-size: 24px;
        text-indent: 25px;
        line-height: 30px;
    }
}

@media (min-width: 800px) {
    .aboutme {
        max-width: 55%;
        padding: 20px 60px;
    }
    .aboutme p {
        font-size: 30px;
        text-indent: 30px;
        line-height: 36px;
    }
}