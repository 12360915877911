.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 0 0;
}

.notFound h2, .notFound p {
    font-family: 'PT Sans', sans-serif;
}

.notFound h2{
    color: #e26200;
    font-size: 48px;
    margin: 24px 0;
}

.notFound p {
    font-size: 24px;
    margin: 8px 0;
}