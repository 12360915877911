.contact-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px 0 300px 0;
}

.contact-me h2 {
    font-family: 'PT Sans', sans-serif;
    font-size: 48px;
    color: #e26200;
    margin: 0 0 30px 0;
}

.contact {
    text-align: left;
    margin: 0 0 10px 30px;
}

.contact a {
    margin: 0 0 0 10px;
    font-size: 16px;
}

.far, .fab {
    color: #e26200;
}

@media (min-width: 500px) {
    
    .contact a {
        font-size: 20px;
    }
}

@media (min-width: 700px) {
    
    .contact a {
        font-size: 30px;
    }
}