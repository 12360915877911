.side-drawer {
    height: 100%;
    background: #373a47;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.6s ease-out;
}

.side-drawer.is-open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem 0 0 0;
}

.side-drawer li {
    margin: 1rem 0 1rem 2rem;
}

.side-drawer a {
    color: white;
    text-decoration: none;
    font-size: 2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color:#E26200;
}


@mdedia (min-width: 700px) {
    .side-drawer {
        display: none;
    }
}